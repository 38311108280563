
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

:root {
  --primary: #20e3cd;
  --graph-primary: #20e3cd;
  --graph-secondary: #dbf3c4;
  --graph-tertiary: #84ceeb;
  --primary-gradient-alt: #dbf3c4;
  --primary-gradient-alt2: #84ceeb;
  --light-color: #EEF2FF;
  --primary-darker: #1ab7a6;
  --primary-gradient-alt-darker: #b8d0a2;
  --primary-contrast-text-color: #222;
  --navigation_color: #eee;
  --menu_color: #223a43;
  --menu_color-darker: #15242a;

  --light-warning: #FFF4DE;
  --light-danger: #FFE2E5;
  --light-success: #e7fff3;
  --light-info: #EEE5FF;
  --info: #5255AA;

  --bs-success: #34C594;

  --light-button-color: #b0b8c5;
  --light-button-text-color: #6B7280;
}

$my-custom-typography-config: mat.define-typography-config(
  $headline:  mat.define-typography-level(24px, 28.4px, 500, 'Roboto', -0.2px),
  $title: mat.define-typography-level(20px, 24.4px, 500, 'Roboto', -0.2px),
  $subheading-2:  mat.define-typography-level(18px, 22.4px, 500, 'Roboto'),
  $subheading-1:  mat.define-typography-level(16px, 19.4px, 500, 'Roboto'),
  $body-1:  mat.define-typography-level(18px, 22.4px, 400, 'Roboto', 0)
);

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  color: white;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  color: white;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core($my-custom-typography-config);

.text-contrast{
  color: var(--primary-contrast-text-color);
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$demo1-primary: mat.define-palette(mat.$indigo-palette);
$demo1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$demo1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$demo1-theme: mat.define-light-theme((
  color: (
    primary: $demo1-primary,
    accent: $demo1-accent,
    warn: $demo1-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($demo1-theme);

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";
@import "./custom";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

html, body { height: 100%; }
body { margin: 0; }

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', "Helvetica Neue", sans-serif; }


.custom-logo{
  max-width: 170px;
}

.breadcrumb-bar{

  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;

  nz-breadcrumb-item{
    font-size:12px;
    text-transform: uppercase;
    font-weight: 500;

    strong{
      font-weight: 800;
    }
  }

}

.single-tab:focus-visible, .single-tab {
  outline: none!important;
}

.cursor-outline{
  cursor: pointer;
}

.password-container{
  position: relative;

  .eye-icon{
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;

    &:hover{
      opacity: 1;
    }

    &.shift-right{
      right: 35px;
    }

    i{
      font-size: 20px;
    }
  }

}


.aside-light .menu .menu-title{

  color: var(--navigation_color) !important;

}

.aside.aside-light{
  background: -webkit-linear-gradient(to bottom, var(--menu_color), var(--menu_color-darker));
  background: linear-gradient(to bottom, var(--menu_color), var(--menu_color-darker));
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
    fill: green !important;
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
    fill: black !important;
  }
}

#svglogo {
  //fill: black !important;
  opacity: .8;
  &:hover {
    //fill: url(#grad1) !important;
    opacity: 1;
    animation: wiggle 2s linear infinite;
  }
}

.hidden {
  display: none !important;
}
.aside.aside-light-mode{
  background: #ffffff;
  box-shadow: -1px -1px 28px 0px rgb(41 40 42 / 19%) !important;

  .menu-link {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 1.55rem;
    margin-bottom: 1.55rem;
    padding-left: unset !important;
    padding-right: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
    padding: 10px !important;
    border-radius: 7px;
    &:hover {
      background-color: #f6f6f6 !important;
    }
  }
  .menu-title {
    color: #474747 !important;
  }
}

.tabset{
  *{
    outline:none;
  }
}

.modal-enterprise{
  background-color: var(--menu_color-darker);
  .ant-modal-body, .ant-modal-footer{
    background-color: var(--menu_color-darker);
  }
}
