
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #494949;
@lightshadow: #e5e5e5;

@select-item-active-bg: #bebebe !important;

@font-family: 'Roboto', 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';

@pagination-item-bg-active: @primary-color;

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){

  background-color: #bebebe !important;


}

// @primary-color: #1890ff;

.ant-modal{

  top: 30px !important

}

.form-control-solid.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none;
  padding: 0;
  background-color: transparent;
}

.search-box-container .form-control.ant-select:not(.ant-select-customize-input) .ant-select-selector, .form-control.remove-nz-styling.form-control.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none;
  background-color: transparent;
}

nz-select.currency_selector{
  width: 80px !important;
  flex: none !important;
  padding: 6px 6px 6px 20px !important;
}

.form-control.remove-nz-styling{
  padding: 6px;
}

.form-control-solid.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none;

}

.ant-select-selection-item{
  width: 100px;
}

.ant-modal.fullscreen-modal{
  width: calc(100vw - 16px) !important;

  @media(max-width: 800px){
    width: calc(100vw) !important;
  }

  height: 100vh !important;
  top:0 !important;
  left:0 !important;
  margin:0 !important;
  padding:0 !important;
  max-width: 100vw;

  .ant-modal-content{
    height:100%;

    .ant-modal-body{
      height: 100%;
    }

  }

}

.fullscreen-modal-container{
  overflow: hidden !important;
}

.fc-daygrid-event{

  font-weight:bold;
  font-size: 1rem !important;

}

.ant-upload.ant-upload-drag{
  background-color: transparent;
  border: 3px dashed #d9d9d9;
  border-radius: 12px;
}
