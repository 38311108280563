//
// Stepper Links
//

// Base
.stepper.stepper-links {
    .stepper-nav {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

        .stepper-item {
			position: relative;
			flex-shrink: 0;
			margin: 1rem 1.5rem;

			&:after {
				content: " ";
				position: absolute;
				top: 2.3rem;
				left: 0;
				height: 2px;
				width: 100%;
				background-color: transparent;
				transition: $transition-link;
			}

            .stepper-title {
                height: 50px;
                width: 50px;
                display: flex;
                align-content: center;
                justify-content: center;
                border-radius: 100%;
                border: 2px solid var(--primary);
                color: var(--primary);
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 45px;
            }

            // Current
            &.current {


				.stepper-title {
					color: $white;
          background: -webkit-linear-gradient(to bottom right, var(--primary), var(--primary-gradient-alt));
          background: linear-gradient(to bottom right, var(--primary),  var(--primary-gradient-alt));
				}

				&:after {
				}
			}

			&.completed {
				.stepper-title {
          color: $white;
          background-color: $primary;
				}
			}
        }
    }
}
