@keyframes appear {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

.ant-pagination-item-active a{
  color: $white !important;
}

.ant-pagination-item, .ant-pagination-item-link, .ant-pagination-next, .ant-pagination-prev{
  border: none !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}


.newCustomerModal{


}

.search-bar{
  .search{
    flex: 1;
  }
  .sorting-tool{
    width: 160px;
  }
}

.badge.status-badge-dot{

}

.input-group-sm .input-group-text{
  line-height: 1;
}

.multi-select-icon{

  svg{
    width: 35px!important;
    height:35px !important;
  }

}

.align-vertical-center-table{
  vertical-align: center;
  text-align: center;
}

.search-filter-dropdown.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-radius: 0.475rem;
    height: 46px;
  }

  .ant-select-selection-item{
    line-height:47px;
  }

}

.dataTables_wrapper{
  .row:nth-child(3n){
    .col-sm-12:first-child{
      display: none;
    }
    .col-sm-12:last-child{
      width: 100% !important;
    }
  }
}

@media(max-width: 500px){
  .card .card-body, .card .card-header{
    padding: 2rem 1.6rem;
  }
}

.job-view-selector .ant-select-arrow{

  right: 20px;

}

.ant-modal.newWorkItemModal{

  width: 800px !important;

}

.entity-list{

  margin-top: 1.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
  border-radius: 7px;

  .table-responsive {
    border-radius: 7px;
  }

  table{
    table-layout: fixed;
  }

  table thead{
    background-color: white;
    border-bottom: 1px solid #cccccc1a;

    .checkbox-column{
      width: 70px;
      vertical-align: middle;
    }

    .actions-column{
      width: 180px;
    }

    th{
      vertical-align: middle;
      text-transform: uppercase;
      font-size:0.9rem;
      padding: 0.75rem 0.75rem 0.75rem 0.75rem;
      font-weight: 600;
      color: #939cad;

    }
  }

  tbody{

    .actions-column{
      width: 180px;
    }

    .checkbox-column{
      width: 70px;
      vertical-align: middle;
    }

    td{
      vertical-align: middle;
      background-color: white !important;
    }

    .btn-light{
      background-color: #F3F4F6;
    }

    .btn-icon{

      i{
        font-size: 17px;
      }

    }


  }

}


.text-align-right{
  text-align: right;
}


.entity-search-container{

  width: 40%;

  /* TODO ADD MOBILE BREAKPOINT FOR THIS */

  .search-field-container{

    position: relative;

    .icon-container{
      position: absolute;
      height: 100%;
      width: 20px;
      padding-top:2px;
      top:0;
      right:10px;
      display: flex;
      align-items: center;
    }

  }

}


/* WHITE LABEL STUFF BELOW */

.text-theme-primary{
  color: var(--primary) !important;
}

.text-theme-secondary{
  color: var(--primary-gradient-alt);
}

// STANDARD BUTTON
.btn-theme{

  text-transform: uppercase;
  font-weight: 600;
  // NO OUTLINE
  background: var(--primary);
  background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
  background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
  color: var(--primary-contrast-text-color);
  &:hover{
    color: var(--primary-contrast-text-color);
    background: -webkit-linear-gradient(to right, var(--primary-darker), var(--primary-gradient-alt-darker));
    background: linear-gradient(to right, var(--primary-darker),  var(--primary-gradient-alt-darker));
  }

  i{
    color: var(--primary-contrast-text-color);
  }

  &.btn-lg{
    i{
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
    span{
      line-height: 1;
      font-size: 1rem;
      font-weight: 600;
    }
  }

}

.btn-contrast{

  background: var(--primary-contrast-text-color);
  color: var(--primary);


}

.btn-outline-theme{

  text-transform: uppercase;
  font-weight: 500;
  color: var(--primary);
  border-color: var(--primary) !important;
  border: 3px solid !important;
  transition: all ease 150ms !important;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  *{
    transition: all ease 150ms !important;
  }

  span, i{
    color: var(--primary);
  }


  &:hover{
    background: var(--primary);
    background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
    background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
    color: var(--primary-contrast-text-color) !important;
    border-color: var(--primary) !important;

    i, span{
      color: var(--primary-contrast-text-color) !important;
    }
  }

}

.btn-outline-light-theme{

  text-transform: uppercase;
  font-weight: 500;
  color: var(--light-button-color);
  border-color: var(--light-button-color) !important;
  border: 3px solid !important;
  transition: all ease 150ms !important;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  *{
    transition: all ease 150ms !important;
  }

  span, i{
    color: var(--light-button-text-color);
  }


  &:hover{
    background: var(--light-button-color);
    color: var(--primary-contrast-text-color) !important;
    border-color: var(--light-button-color) !important;

    i, span{
      color: var(--primary-contrast-text-color) !important;
    }
  }

}

.bg-grad-theme{
  background: var(--primary);
  background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
  background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
}

.alert-theme{

  background: var(--primary);
  color: var(--primary-contrast-text-color) !important;
  *{
    color: var(--primary-contrast-text-color) !important;
  }

}

.state-icon{

  margin: auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.475rem;
  background-color: #e1e1e1;

  i{
    color: #222;
  }

  &.success{
    i{
      color: var(--bs-success)
    }
    background-color: var(--light-success);
  }

  &.danger{
    i{
      color: var(--bs-danger)
    }
    background-color: var(--light-danger);
  }

}


.enterprise-badge {

  display: inline;
  border-radius: 8px;
  padding: 3px 8px;
  margin-left: 10px;
  font-size: 12px;
  position: relative;
  top:-3px;

  span{
    color:var(--primary-contrast-text-color);
    text-shadow: 0 0 3px rgba(0,0,0,0.4);
  }

  background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
  background: linear-gradient(to right,  var(--primary), var(--primary-gradient-alt));
}

.grad-theme-text{

  background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
  background: linear-gradient(to right,  var(--primary), var(--primary-gradient-alt));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}


.btn{
  text-transform: uppercase;
}

.lookalike-tabset {
  display: flex;
  border-bottom: 1px solid var(--bs-light);
  margin: 2.5rem 0 2rem 0 !important;
}
.tabset{

  display: flex;
  border-bottom: 1px solid var(--bs-light);
  margin: 2.5rem 0 3rem 0 !important;
}

.single-tab{
  margin-right: 40px;
  flex: 0 1 auto;
  cursor: pointer;
  padding-bottom: 20px;
  text-transform: uppercase;
  position: relative;

  &.selected{

    &:after{
      width: 100%;
      height: 5px;
      bottom: 0;
      left:0;
      content: "";
      position: absolute;
      background: var(--primary);
      background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
      background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
    }

  }


  &:last-child{
    margin-right:0;
  }

  h4{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    font-family: var(--bs-body-font-family);
  }
}

.filter-dialog-button{

  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  padding: 0 0.8rem;
  margin-bottom: 11px;
  height: 49px;
  max-height: 49px !important;
  border-radius: 0.475rem;
  cursor:pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #00000012;
  margin-right: 11px;

  &:not(.hasValue){
    &:hover{
      background-color: var(--menu_color-darker);
      .filter-title{
        background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
        background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        i{
          background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
          background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }
      }
    }
  }

  &.hasValue{
    background: -webkit-linear-gradient(to right, var(--primary), var(--primary-gradient-alt));
    background: linear-gradient(to right, var(--primary),  var(--primary-gradient-alt));

    p{
      color: var(--primary-contrast-text-color) !important;

      &.filter-title{
        margin-bottom: 5px;
      }
    }

    .cancel-button{
      border-color: var(--primary-contrast-text-color) !important;
      i{
        color:var(--primary-contrast-text-color) !important;
      }
    }

  }

  .cancel-button-container{
    width: 21px;
    position: relative;
    z-index: 5;
    height: 21px;
    margin-left: 10px;
    .cancel-button{
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #666;
      border-radius: 100%;
      i{
        text-align: center;
        position: relative;
        top: 1px;
        color: #666;
        font-size: 8px;
      }
    }
  }

  p, span{
    color: #bfbfbf;
    line-height: 1;
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--bs-body-font-family);


    &.filter-title{
      margin-bottom: 0;
    }

    &.filter-value{
      margin-bottom: 0;
      -webkit-animation: appear 200ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 200ms both;
      animation: appear 200ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 200ms both;
      color: #515867;
      font-size: 0.8rem;
      font-weight: 700;
    }

  }

}

.ant-tooltip-inner{

 background-color: white;
 color: var(--primary);
 box-shadow: none;
 font-weight: bold;

}

.ant-tooltip-arrow{
  visibility: hidden;
}

.bg-light-theme{
  position: relative;
  &:before{
    background-color: var(--primary);
    opacity:0.1;
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 0.475rem;
    border-bottom-right-radius: 0.475rem;
  }
}

.interests-dropdown.ant-select-multiple{

   .ant-select-selection-item{
    flex: 1 !important;
  }

}


.ant-pagination-item-active{

  background-color: var(--primary);

  a{
    color: var(--primary-contrast-text-color);
  }

}

.status-badge-dot{
  width: 4px;
  height:4px;
  background-color: #222;
  border: 6px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background-clip: content-box;
  box-sizing: content-box;

  &.status-light-warning{
    background-color: var(--bs-warning);
    border: 5px solid var(--light-warning);

  }

  &.status-light-info{
    background-color: var(--info);
    border: 5px solid var(--light-info);

  }

  &.status-light-danger{
    background-color: var(--bs-danger);
    border: 5px solid var(--light-danger);

  }

  &.status-light-success{
    background-color: var(--bs-success);
    border: 5px solid var(--light-success);

  }

}


.angular-editor-wrapper .angular-editor-textarea{

}

button.btn.btn-xs{

  padding: calc(0.35rem + 1px) calc(1.15rem + 1px) !important;
  font-size: 13px;

}



.weight-dropdown {
  flex: 1;
  max-width: 70px;
  font-size: 12px;

  .ant-select-item-option {
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-size: 12px;
  }
}

.white-spinner .ant-spin-dot-item{
  background-color: var(--primary) !important;
}


.helpTool{
  .ant-tooltip-inner{
    background-color: rgba(0,0,0,0.8);
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #eee;
    border-radius: 7px;
  }
}
